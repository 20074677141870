import "./App.css";
import NavigationBar from "./components/Navigation/NavigationBar";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import LandingPage from "./pages/LandingPage/LandingPage";
import CreateNewInvestigation from "./pages/CreateNewInvestigation/CreateNewInvestigation";
import Footer from "./components/Footer/Footer";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./styles/theme";
import "./fonts/Roboto/Roboto-Regular.ttf";
import { useKeycloak } from "@react-keycloak/web";
import Loader from "./components/Utilities/Loader/Loader";
import { createContext, useEffect, useState } from "react";
import CountryProfiles from "./pages/CountryProfiles/CountryProfiles";
import Manual from "./pages/Manual/Manual";
import Privacy from "./pages/Privacy/Privacy";

export const FermiContext = createContext(null);

function App() {
  const { keycloak, initialized } = useKeycloak();
  const [userData, setUserData] = useState({});

  useEffect(() => {
    if (initialized) {
      keycloak
        .loadUserInfo()
        .then((response) => {
          setUserData(response);
        })
        .catch((error) => {
          setUserData({});
        });
    }
  }, [keycloak, initialized]);

  return (
    <ThemeProvider theme={theme}>
      <FermiContext.Provider value={{ userData }}>
        <BrowserRouter>
          <div className="App">
            <NavigationBar />
            {!keycloak.authenticated ? (
              <Loader />
            ) : (
              <Routes>
                <Route exact path="/" element={<LandingPage />} />
                <Route
                  path="/create-new-investigation"
                  element={<CreateNewInvestigation />}
                />
                <Route path="*" element={<LandingPage />} />
                <Route path="/country-profiles" element={<CountryProfiles />} />
                <Route
                  path="/country-profiles/:country"
                  element={<CountryProfiles />}
                />
                <Route path="/manual" element={<Manual />} />
                <Route path="/privacy-policy" element={<Privacy />} />
              </Routes>
            )}
            <Footer />
          </div>
        </BrowserRouter>
      </FermiContext.Provider>
    </ThemeProvider>
  );
}

export default App;

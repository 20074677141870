import React from "react";
import { Modal, Box, Typography, Button, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const InfoModal = ({ open, onClose, title, content }) => {
  return (
    <Modal open={open} onClose={onClose}>
      <>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            borderRadius: "4px",
            border: "2px solid #00556F",
            boxShadow: 24,
            p: 4,
            width: "50vw",
            maxHeight: "60vh",
            overflowY: "auto"
          }}
        >
          <IconButton onClick={onClose} sx={{float: "right"}}>
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" component="h2">
            {title}
          </Typography>
          <Typography sx={{ mt: 4 }}>{content}</Typography>
          <Button
            variant="contained"
            onClick={onClose}
            sx={{ mt: 2, display: "block", ml: "auto" }}
          >
            Close
          </Button>
        </Box>
      </>
    </Modal>
  );
};

export default InfoModal;

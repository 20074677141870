import React from "react";
import "../../styles/Manual.scss";

function Manual() {
  return (
    <div id="manual-wrapper">
      <div className="section">
        <h1>FERMI platform - User manual </h1>

        <p>
          Welcome to the Brief user manual for FERMI platform, your guide to
          navigating and maximizing the features of our platform. This manual is
          designed to assist users ensuring an easy and efficient experience.
          Whether you're looking to understand the basic functionalities or
          explore advanced features, this guide provides step-by-step
          instructions. Read on to learn how to set up your account, navigate
          the interface, customize settings, and much more, all aimed at helping
          you get the most out of FERMI platform.{" "}
        </p>
      </div>

      <div className="section">
        <h3>After log in to the platform </h3>
        <p>
          After logging in, you'll land on the main screen of the FERMI
          platform. On the right side, there's a sidebar displaying a list of
          the investigations you've started, as shown in the figure below.{" "}
        </p>
        <Image src={"/img/manual/sidebar.png"} alt="List of investigations" />
      </div>

      <div className="section">
        <h3>New investigation</h3>
        <p>
          When you choose an investigation from the list, if it's complete,
          you'll see its results. There's also a button in the sidebar for
          starting a new investigation. Clicking this button opens the screen to
          create a new investigation, as shown in the figure below.
        </p>
        <Image
          src={"/img/manual/new-investigation.png"}
          alt="Create new investigation"
        />
        <p>
          On this screen, you are requested to provide the following information
          to initiate a new investigation:
        </p>
        <ul>
          <li>
            <strong>Title:</strong> A free text field, which allows you to give
            a name to the investigation, so that you can identify it on the
            list.
          </li>
          <li>
            <strong>Investigation topic:</strong> You must choose which of the
            three topics related to FERMI’s use cases this investigation is
            about.
          </li>
          <li>
            <strong>Identified D&FN post:</strong> You are requested to provide
            the link to the X’s post which will be investigated in this field.
          </li>
          <li>
            <strong>Investigations Viewers:</strong> Select other users from
            your own organisation to share this investigation with (this feature
            is not enabled yet).
          </li>
        </ul>

        <p>
          Upon filling in all fields, the Create Investigation button will begin
          the process and trigger the rest of the FERMI components to start
          collecting the required data and produce their results.
        </p>
      </div>

      <div className="section">
        <h3>Investigation results</h3>
        <p>
          Once an investigation is completed, you can see the results be
          selecting the investigation on the sidebar on the first screen.{" "}
        </p>

        <h4>Investigation’s basic Info</h4>
        <p>
          The top section of this screen contains the basic information about
          this investigation, which you provided when it was initiated.
        </p>
        <Image
          src="/img/manual/inv-basic-info.png"
          alt="Investigation basic information"
        />

        <h4>Investigation’s details</h4>
        <p>
          Below the basic information, a representation of the node graph of the
          X’s posts which have been collected is presented. Each node represents
          an X’s post which is related to the post that was used to initiate the
          investigation either by being a retweet, a quote or a reply to the
          initial post and these connections are presented in different colours.
          Additionally, the size of each node represents the influence of that
          node, whereas the text in it denotes if it is a bot or a human user.
          By hovering over a node, you can see the influence score and the
          sentiment evaluation of that node.
        </p>
        <Image src="/img/manual/node-graph.png" alt="Node graph" />

        <h4>Investigation’s sentiment analysis</h4>
        <p>
          Below the node graph, the next section presents a more comprehensive
          view of the sentiments analysis of all nodes. In the form of a pie
          graph on the left, you can easily see the percentages of each
          sentiment (positive, neutral negative). On the right side, the
          sentiment analysis is presented with a separate bar per day, each
          displaying visually the number of X’s posts classified for each type
          of sentiment, allowing you to see the sentiment and the number or
          posts over time.
        </p>
        <Image
          src="/img/manual/sentiment-analysis-graph.png"
          alt="Sentiment analysis graphs"
        />

        <h4>Investigation’s crimes analysis</h4>
        <p>
          The next section of the UI is devoted to the crime analysis. You can
          select one of the highlighted countries on the map in order to be
          presented with the results of the crime analysis. These results are
          presented per NUTS 2 region of that country and are also separated in
          weeks from the week the tweet was created. The list contains the
          estimated increase or decrease in the number of different types of
          crimes since the previous week.
        </p>
        <Image src="/img/manual/crime-analysis.png" alt="Crime analysis" />
        <h4>Investigation’s impact analysis and suggested countermeasures</h4>
        <p>
          The last section of the FERMI GUI presents the estimated impact of the
          X’s post of this investigation along with the suggested
          countermeasures. The impact is displayed graphically, whereas the
          countermeasures are presented in the form a list. Clicking on a
          specific countermeasure will make a more elaborate description of it
          available (in future versions). Clicking on the country profiles also
          provides some extra information concerning the media literacy and the
          sources of information of the participating countries.
        </p>
        <Image src="/img/manual/impact.png" alt="Impact and Countermeasures" />
      </div>
    </div>
  );
}

export default Manual;

function Image({ src, alt }) {
  return (
    <div className="custom-image">
      <img src={src} alt={alt} />
      <span>{alt}</span>
    </div>
  );
}

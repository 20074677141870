import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "../../../styles/MapChartInformation.scss";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

function MapChartCountryInformation({ data = null, country, region }) {
  const [regionData, setRegionData] = useState(null);

  useEffect(() => {
    if (data !== null) {
      let countryData = data.country.find(
        (element) => element.Country_name === country
      );
      if (countryData) {
        setRegionData(
          countryData.Region.find(
            (element) => element.Region_name === region.id
          )
        );
      }
    }
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  const trasformCrimeName = (crime) => {
    let name = crime.replace("crime_", "").replaceAll("_", " ");
    let vandalismOfProperty = ["vandalism", "of", "property"].some((word) =>
      name.toLowerCase().includes(word.toLowerCase())
    );
    let larcenyTheft = ["larceny", "theft"].some((word) =>
      name.toLowerCase().includes(word.toLowerCase())
    );
    if (vandalismOfProperty) {
      return "Destruction/Damage/Vandalism of property";
    } else if (larcenyTheft) {
      return "Larceny/Theft";
    } else {
      return name;
    }
  };

  const createCrimePredictionRows = (data) => {
    let crimesArray = [];

    data.crime.forEach((crime) => {
      Object.keys(crime).forEach((key) => {
        crimesArray.push({
          crimeName: trasformCrimeName(key),
          predicted: crime[key][0].predicted_occurrence_of_crimes,
          rateOfChange: crime[key][0].rate_of_change_percentage,
        });
      });
    });

    return (
      <React.Fragment>
        {crimesArray.map((crime, index) => (
          <tr key={index}>
            <td>{crime.crimeName}</td>
            <td>
              <div className="table-content">
                {crime.predicted}
                <>
                  {" "}
                  (
                  {crime.rateOfChange > 0 ? (
                    <ArrowUpwardIcon
                      fontSize="tiny"
                      style={{ color: "#91CC75" }}
                      className="table-icon"
                    />
                  ) : crime.rateOfChange < 0 ? (
                    <ArrowDownwardIcon
                      fontSize="tiny"
                      style={{ color: "#EE6666" }}
                      className="table-icon"
                    />
                  ) : null}{" "}
                  {Math.abs(crime.rateOfChange)}% )
                </>
              </div>
            </td>
          </tr>
        ))}
      </React.Fragment>
    );
  };

  const getDate = (data) => {
    const date = new Date(data);
    const day =
      date.getDate().toString().length === 1
        ? "0" + date.getDate()
        : date.getDate();
    const month =
      (date.getMonth() + 1).toString().length === 1
        ? "0" + (date.getMonth() + 1)
        : date.getMonth() + 1;
    return day + "/" + month + "/" + date.getFullYear();
  };

  return (
    <div id="map-information">
      {regionData && (
        <div className="regions">
          <div className="region-wrapper">
            {/* <p className="title">
                Region: <span>{region.Region_name}</span>
              </p> */}
            <div className="region-accordion">
              {regionData.timestamps.map((timestamp, tIndex) => (
                <Accordion key={tIndex}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`accordion-${tIndex}`}
                    id={`accordion-${tIndex}`}
                  >
                    <Typography>
                      <span className="acc-title">
                        Week {tIndex + 1} |{" "}
                        {getDate(timestamp.date.split("/")[0])} -{" "}
                        {getDate(timestamp.date.split("/")[1])}
                      </span>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <table>
                      <tbody>
                        <tr>
                          <th>Crime</th>
                          <th>Predicted</th>
                        </tr>
                        {createCrimePredictionRows(timestamp)}
                      </tbody>
                    </table>
                  </AccordionDetails>
                </Accordion>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default MapChartCountryInformation;

import {
  Box,
  Chip,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

import React from "react";

function MultiSelect({
  id,
  label,
  items,
  selectedItems,
  handleChange,
  handleDelete,
  isMultiSelect = true, // New prop to distinguish between single and multi-select
}) {
  const getLabel = (value) => {
    const item = items.find(
      (element) => element.sub === value || element.value === value
    );
    return item ? item.preferred_username || item.label : "";
  };

  return (
    <React.Fragment>
      <InputLabel id={`${id}-label`}>{label}</InputLabel>
      <Select
        labelId={`${id}-label`}
        style={{ width: "100%" }}
        id={`${id}-label`}
        name={`${id}`}
        multiple={isMultiSelect}
        value={selectedItems}
        onChange={handleChange}
        input={<OutlinedInput id={`select-${id}`} label={`${label}`} />}
        renderValue={(selected) =>
          isMultiSelect ? (
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
              {selected.map((value) => (
                <Chip
                  key={value}
                  label={getLabel(value)}
                  clickable
                  deleteIcon={
                    <HighlightOffIcon
                      onMouseDown={(event) => event.stopPropagation()}
                      fontSize="small"
                      style={{ color: "#B3B3B3" }}
                    />
                  }
                  onDelete={() => handleDelete(value)}
                />
              ))}
            </Box>
          ) : (
            getLabel(selected)
          )
        }
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        }}
      >
        {items.map((item, index) => (
          <MenuItem
            key={index}
            value={isMultiSelect ? item.sub : item.value}
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            {isMultiSelect ? item.preferred_username : item.label}
            {/* {selectedItems.find((element) => element === item) && (
              <HighlightOffIcon fontSize="small" style={{ color: "#B3B3B3" }} />
            )} */}
          </MenuItem>
        ))}
      </Select>
    </React.Fragment>
  );
}

export default MultiSelect;

import React, { useEffect, useRef, useState } from "react";
import {
  ComposableMap,
  Geographies,
  Geography,
  ZoomableGroup,
} from "react-simple-maps";
import "../../styles/MapChart.scss";
import geo from "../../data/nut2data.json";
import PopUp from "../PopUp/PopUp";
import MapChartCountryInformation from "./MapChartCountryInformation/MapChartCountryInformation";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import CenterFocusWeakIcon from "@mui/icons-material/CenterFocusWeak";
import { Button } from "@mui/material";
// import EastIcon from "@mui/icons-material/East";

function MapChart({ data }) {
  const [selectedRegion, setSelectedRegion] = useState({ na: "", id: "" });
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [highlightedCountries, setHighlightedCountries] = useState([]);
  const [countryCodes, setCountryCodes] = useState([]);
  const [openCountryPopUp, setCountryPopUp] = useState(false);
  const [zoom, setZoom] = useState(2);

  const [nuts2data, setNuts2Data] = useState(null);

  useEffect(() => {
    if (data.country && data.country.length > 0) {
      let countryArray = [];
      data.country.forEach((element) => {
        countryArray.push(element.Country_name);
      });
      setHighlightedCountries(countryArray);
    }
  }, [data.country]);

  const filterGeography = (codes) => {
    let filteredData = [];
    codes.forEach((code) => {
      filteredData.push(
        ...geo.features.filter((element) =>
          element.properties.id.startsWith(code)
        )
      );
    });

    return filteredData;
  };

  useEffect(() => {
    // find which countries should be displayed in the map
    if (data.country && data.country.length > 0) {
      let codes = [];
      data.country.forEach((element) => {
        if (element.Country_name === "Belgium") {
          codes.push("BE");
        } else if (element.Country_name === "Finland") {
          codes.push("FI");
        } else if (element.Country_name === "Germany") {
          codes.push("DE");
        }
      });

      setCountryCodes(codes);

      // filter geo json data
      setNuts2Data({ ...geo, features: filterGeography(codes) });
    }
  }, [data.country]);

  // const handleCountryHover = (geography) => {
  //   console.log(geography.properties)
  // };

  // const handleCountryLeave = () => {
  // };

  const handleCountryClick = (geography) => {
    let code = geography.properties.id.substring(0, 2);
    if (countryCodes.includes(code)) {
      setSelectedRegion(geography.properties);
      setSelectedCountry(
        code === "DE" ? "Germany" : code === "FI" ? "Finland" : "Belgium"
      );
      setCountryPopUp(true);
    }
  };

  const handleCountryListClick = (country, region) => {
    setSelectedRegion(region);
    setSelectedCountry(country);
    setCountryPopUp(true);
  };

  const zoomableGroupRef = useRef(null);

  useEffect(() => {
    const handleWheel = (event) => {
      event.preventDefault(); // Prevent the default scroll behavior
      event.stopPropagation(); // Stop event propagation
    };

    const zoomableGroup = zoomableGroupRef.current;
    zoomableGroup.addEventListener("wheel", handleWheel);

    return () => {
      zoomableGroup.removeEventListener("wheel", handleWheel);
    };
  }, []);

  const handleZoomIn = () => {
    setZoom(zoom + 0.5);
  };

  const resetZoom = () => {
    setZoom(2);
  };

  const handleZoomOut = () => {
    if (zoom > 1) {
      setZoom(zoom - 0.5);
    }
  };

  const checkIfRegionExists = (countryName, regionId) => {
    let country = data.country.filter(
      (element) => element.Country_name === countryName
    );
    if (country.length === 1) {
      for (let i = 0; i < country[0].Region.length; i++) {
        if (country[0].Region[i].Region_name === regionId) {
          return true;
        }
      }
    }

    return false;
  };

  const filterHighlightedRegions = (regionId) => {
    if (regionId.startsWith("DE")) {
      if (checkIfRegionExists("Germany", regionId)) {
        return true;
      }
    } else if (regionId.startsWith("BE")) {
      if (checkIfRegionExists("Belgium", regionId)) {
        return true;
      }
    } else if (regionId.startsWith("FI")) {
      if (checkIfRegionExists("Finland", regionId)) {
        return true;
      }
    }

    return false;
  };

  const findRegionsToDisplay = (country) => {
    let regions = data.country.find((item) => item.Country_name === country);

    let regionData = [];

    if (regions) {
      regions.Region.forEach((region) => {
        let regionItem = nuts2data.features.find(
          (element) => element.properties.id === region.Region_name
        );
        if (regionItem) {
          regionData.push(regionItem);
        }
      });
    }

    return regionData;
  };

  const handleRegionColor = (regionId) => {
    if (regionId.startsWith("DE")) {
      return checkIfRegionExistsAndGetColor("Germany", regionId);
    } else if (regionId.startsWith("BE")) {
      return checkIfRegionExistsAndGetColor("Belgium", regionId);
    } else if (regionId.startsWith("FI")) {
      return checkIfRegionExistsAndGetColor("Finland", regionId);
    }

    return false;
  };

  const checkIfRegionExistsAndGetColor = (countryName, regionId) => {
    let country = data.country.filter(
      (element) => element.Country_name === countryName
    );
    if (country.length === 1) {
      for (let i = 0; i < country[0].Region.length; i++) {
        if (country[0].Region[i].Region_name === regionId) {
          return country[0].Region[i].color ?? "";
        }
      }
    }

    return "";
  };

  return (
    <>
      <div className="map-guidelines">
        <p>
          Click on the highlighted country regions to view crime predictions per
          region & period.
        </p>
      </div>
      <div className="map-container">
        {highlightedCountries.length > 0 && (
          <div id="countries-list">
            <div className="wrapper">
              <div>Countries List</div>

              <ul>
                {highlightedCountries.map((country, index) => (
                  <>
                    <li key={index} className="non-clickable">
                      {country}
                      {/* <EastIcon fontSize="tiny" /> */}
                    </li>
                    <hr />
                    {findRegionsToDisplay(country).map((item) => (
                      <li
                        key={item.properties.na}
                        onClick={() => {
                          handleCountryListClick(country, item.properties);
                        }}
                        onMouseEnter={() => setSelectedRegion(item.properties)}
                        // onMouseLeave={() =>
                        //   setSelectedRegion({ na: "", id: "" })
                        // }
                      >
                        {item.properties.id} - {item.properties.na}
                      </li>
                    ))}
                    <br />
                  </>
                ))}
              </ul>
            </div>
          </div>
        )}

        <div id="zoom-buttons">
          <Button onClick={handleZoomIn}>
            <ZoomInIcon />
          </Button>
          <Button onClick={resetZoom}>
            <CenterFocusWeakIcon />
          </Button>
          <Button onClick={handleZoomOut}>
            <ZoomOutIcon />
          </Button>
        </div>

        <ComposableMap
          width={400}
          height={200}
          style={{ width: "100%", height: "auto" }}
        >
          <ZoomableGroup ref={zoomableGroupRef} center={[0, 50]} zoom={zoom}>
            <Geographies geography={geo}>
              {({ geographies }) =>
                geographies.map((geo) => (
                  <Geography
                    key={geo.rsmKey}
                    geography={geo}
                    onMouseEnter={() => setSelectedRegion({ na: "", id: "" })}
                    // onMouseLeave={handleCountryLeave}
                    onClick={() => handleCountryClick(geo)}
                    className={`default-countries ${
                      filterHighlightedRegions(geo.properties.id)
                        ? "highlighted-country"
                        : ""
                    } ${
                      selectedRegion.id === geo.properties.id
                        ? "selected-country"
                        : ""
                    } ${handleRegionColor(geo.properties.id)}`}
                    style={{
                      default: {
                        outline: "none",
                        stroke: `${
                          filterHighlightedRegions(geo.properties.id)
                            ? "#4CA98E"
                            : "#313131"
                        }`,
                        strokeWidth: `${
                          filterHighlightedRegions(geo.properties.id)
                            ? "0"
                            : "0.2px"
                        }`,
                      },
                      hover: {
                        outline: "none",
                      },
                      pressed: {
                        outline: "none",
                      },
                    }}
                    // data-tooltip-id={`tool-${geo.properties.name}`}
                  />
                ))
              }
            </Geographies>
          </ZoomableGroup>
        </ComposableMap>
        <PopUp
          openPopUp={openCountryPopUp}
          setOpenPopUp={setCountryPopUp}
          title={`Region: ${selectedRegion.na} - ${selectedRegion.id}`}
          closeFunction={() => {
            setSelectedRegion({ na: "", id: "" });
          }}
        >
          <MapChartCountryInformation
            data={data}
            region={selectedRegion}
            country={selectedCountry}
          />
        </PopUp>
      </div>
    </>
  );
}

export default MapChart;

import React, { useEffect, useState } from "react";
import { Handle } from "reactflow";
import "../../styles/CircularNode.scss";
import { Tooltip } from "@mui/material";

const CircularNode = ({ data }) => {
  const [tooltipContent, setTooltipContent] = useState("");

  useEffect(() => {
    let hiddenTooltip = data.info.properties.tweet_created_at ? false : true;
    if (!hiddenTooltip) {
      var date = new Date(
        data.info.properties.tweet_created_at.replace(/\s/g, "")
      );

      var day =
        date.getDate().toString().length > 1
          ? date.getDate()
          : "0" + date.getDate();

      var month =
        (date.getMonth() + 1).toString().length > 1
          ? date.getMonth() + 1
          : "0" + (date.getMonth() + 1).toString();

      var year = date.getFullYear();

      var sentiment = ["neutral", "positive", "negative"].includes(
        data.info.properties.prediction_label
      );

      setTooltipContent(
        <div className="tooltip-node-content">
          <p>
            Node created at:{" "}
            <span>
              {day}/{month}/{year}
            </span>
          </p>
          <p>
            Account influence score:{" "}
            <span>{data.info.properties.influenceScore}</span>
          </p>
          <div className="sentiment">
            <p className={`title ${sentiment ? "" : "no-sentiment"}`}>
              Tweet’s sentiment:
            </p>
            <div>
              <div className="speedometer">
                {sentiment &&
                  ["neutral", "positive", "negative"].includes(
                    data.info.properties.prediction_label
                  ) && (
                    <img
                      src={`/img/svg/speed-nodes/${data.info.properties.prediction_label}.svg`}
                      alt="sentiment speedometer"
                    />
                  )}
                <span>
                  {sentiment ? data.info.properties.prediction_label : "N/A"}
                </span>
              </div>

              {sentiment && (
                <p>
                  <span>
                    {Number(
                      data.info.properties.prediction_probability * 100
                    ).toFixed(2)}
                    %
                  </span>{" "}
                  probability {data.info.properties.prediction_label}
                </p>
              )}
            </div>
          </div>
        </div>
      );
    }
  }, [data]);

  const nodeSize = () => {
    var score = data.info.properties.influenceScore;
    var maxExtraSize = 150;
    if (score > 0) {
      return 150 + score * maxExtraSize;
    } else {
      return 150;
    }
  };

  return (
    <Tooltip
      title={tooltipContent}
      PopperProps={{
        sx: {
          "& .MuiTooltip-tooltip": {
            backgroundColor: "transparent",
            border: "none",
          },
        },
      }}
    >
      <div
        className={`circular-node ${data.info.properties.botClassify === 0 ? "bot-node" : ""}`}
        style={{ width: `${nodeSize()}px`, height: `${nodeSize()}px` }}
      >
        <Handle
          type="target"
          position="left"
          id="a"
          style={{ zIndex: 10, left: -15 }}
        />
        <div className="circular-content">{data.label}</div>
        <Handle
          type="source"
          position="right"
          id="b"
          style={{ zIndex: 10, right: -15 }}
        />
      </div>
    </Tooltip>
  );
};

export default CircularNode;

import React, { useCallback, useEffect, useRef, useState } from "react";
import ReactFlow, {
  addEdge,
  ConnectionLineType,
  Panel,
  useNodesState,
  useEdgesState,
  MarkerType,
  Controls,
  MiniMap,
} from "reactflow";
import "reactflow/dist/style.css";
import CircularNode from "./CircularNode";
import "../../styles/NodeGraph.scss";
import { Button } from "@mui/material";
import ColorsTable from "./NodeGraphDetails/ColorsTable";

const getNodes = (nodes, tweetId) => {
  //find tweet that was created first
  const earliestTweet = nodes.reduce((earliest, current) => {
    return new Date(current.properties.tweet_created_at) <
      new Date(earliest.properties.tweet_created_at)
      ? current
      : earliest;
  }, nodes[0]);

  let newNodes = [];
  nodes.forEach((node) => {
    newNodes.push({
      id: node.id,
      data: {
        label: `${
          node.properties.botClassify === 1
            ? "Human\nAccount"
            : node.properties.botClassify === 0
            ? "Bot\nAccount"
            : "Unknown\nAccount Type"
        }`,
        info: node,
      },
      connectable: false,
      draggable: true,
      type: "circularNode",
      className: `${
        node.properties.botClassify === 0
          ? "non-bot-node"
          : node.properties.botClassify === 1
          ? "bot-node"
          : ""
      } ${node.properties.tweetId === tweetId ? "initial-node" : ""} ${node.properties.tweetId === earliestTweet.properties.tweetId ? "first-tweet" : ""}`,
    });
  });

  return newNodes;
};

const getEdges = (edges) => {
  let newEdges = [];
  edges.forEach((edge) => {
    let strokeColor = "";
    strokeColor =
      edge.label === "REPLY_TO" || edge.label === "REPLY"
        ? "#E29650"
        : edge.label === "RETWEETS" || edge.label === "REBLOG"
        ? "#5653DF"
        : edge.label === "QUOTED"
        ? "#86CB67"
        : "black";
    newEdges.push({
      id: `e${edge.start.id}-${edge.end.id}`,
      source: edge.start.id,
      target: edge.end.id,
      markerEnd: {
        type: MarkerType.ArrowClosed,
        color: strokeColor,
      },
      label: edge.label === "RETWEETS" ? "REPOSTS" : edge.label,
      style: { stroke: strokeColor, strokeWidth: 3 },
    });
  });

  return newEdges;
};

const nodeTypes = { circularNode: CircularNode };

function NodeGraph({ nodeData, tweetId }) {
  const layoutedNodes = [];
  const layoutedEdges = [];

  const [nodes, setNodes, onNodesChange] = useNodesState(layoutedNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(layoutedEdges);
  const [key, setKey] = useState(0); // key is used as a prop to NodeGraph so it can be re-rendered and recentered every time node data changes

  const workerRef = useRef();

  useEffect(() => {
    // Initialize worker
    workerRef.current = new Worker(
      new URL("./layoutWorker.js", import.meta.url)
    );

    return () => {
      workerRef.current.terminate(); // Cleanup worker on unmount
    };
  }, []);

  useEffect(() => {
    // Post data to worker
    workerRef.current.postMessage({
      nodes: getNodes(nodeData.nodes, tweetId),
      edges: getEdges(nodeData.rels),
      direction: "TB", // Default layout direction
    });

    // Listen for layout results
    workerRef.current.onmessage = (e) => {
      const { nodes: layoutedNodes, edges: layoutedEdges } = e.data;
      setNodes(layoutedNodes);
      setEdges(layoutedEdges);
      setKey((prevKey) => prevKey + 1);
    };
  }, [nodeData]); //eslint-disable-line react-hooks/exhaustive-deps

  const onConnect = useCallback(
    (params) =>
      setEdges((eds) =>
        addEdge(
          { ...params, type: ConnectionLineType.SmoothStep, animated: true },
          eds
        )
      ),
    [] //eslint-disable-line react-hooks/exhaustive-deps
  );

  const onLayout = useCallback(
    (direction) => {
      // Send the current nodes, edges, and layout direction to the worker
      workerRef.current.postMessage({
        nodes,
        edges,
        direction,
      });

      // Listen for the worker's response
      workerRef.current.onmessage = (e) => {
        const { nodes: layoutedNodes, edges: layoutedEdges } = e.data;
        setNodes(layoutedNodes);
        setEdges(layoutedEdges);
      };
    },
    [nodes, edges] //eslint-disable-line react-hooks/exhaustive-deps
  );

  // const onNodeClick = useCallback((event, node) => {
  //   // You can perform any action here when a node is clicked
  //   setSelectedNode(node);
  // }, []);

  const colors = [
    { colorCode: "#00556F", details: "Provided post" },
    { colorCode: "#4CA98E", details: "Original (earliest) post" },
    { colorCode: "#5653DF", details: "Reposts Edge" },
    { colorCode: "#86CB67", details: "Quoted Edge" },
    { colorCode: "#E29650", details: "Reply To Edge" },
    { colorCode: "#F9C858", details: "Unknown node/edge" },
  ];

  return (
    <div id="node-graph-container">
      <ColorsTable colors={colors} />
      <div className="content-container">
        <div className="node-graph-wrapper">
          <ReactFlow
            nodes={nodes}
            edges={edges}
            nodeTypes={nodeTypes}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            onConnect={onConnect}
            connectionLineType={ConnectionLineType.SmoothStep}
            fitView
            key={key}
            // onNodeClick={onNodeClick}
          >
            <Panel position="top-right">
              <div id="layout-buttons">
                <Button variant="contained" onClick={() => onLayout("TB")}>
                  Vertical Layout
                </Button>
                <Button variant="contained" onClick={() => onLayout("LR")}>
                  Horizontal Layout
                </Button>
              </div>
            </Panel>
            <Controls />
            <MiniMap
              nodeStrokeColor={(n) => "#fff"}
              nodeColor={(n) => "#aaa"}
            />
          </ReactFlow>
        </div>
      </div>
    </div>
  );
}

export default NodeGraph;

import React, { useEffect } from "react";
import * as echarts from "echarts";

function PieChart({ chartData, name }) {
  useEffect(() => {
    var chartDom = document.getElementById(name);
    var myChart = echarts.getInstanceByDom(chartDom);

    // Dispose of existing instance if it exists
    if (myChart) {
      myChart.dispose();
    }

    // Reinitialize the chart
    myChart = echarts.init(chartDom);
    
    var option;

    option = {
      tooltip: {
        trigger: "item",
      },
      // legend: {
      //   orient: "horizontal",
      //   left: "left",
      // },
      series: [
        {
          type: "pie",
          radius: "50%",
          color: ["#91CC75", "#B5B5B5", "#EE6666"],
          data: chartData,
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)",
            },
          },
        },
      ],
    };

    option && myChart.setOption(option);
  }, [chartData, name]);
  return <div id={name} style={{ width: "100%", height: "270px" }}></div>;
}

export default PieChart;

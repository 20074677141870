import React from "react";
import "../../styles/Footer.scss";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <footer>
      <div className="page-container">
        <div id="footer-wrapper">
          <div id="eu-info">
            <img src="european-union.png" alt="european union flag" />
            <div>
              This project has received funding from the European Union's HE
              research and innovation programme under the grant agreement No.
              101073980
            </div>
          </div>
          <div id="links">
            <Link
              to="#"
              className="footer-link"
              onClick={(e) => {
                window.location.href = "mailto:fermitechsupport@lists.itml.gr";
                e.preventDefault();
              }}
            >
              Contact support
            </Link>
            <Link
              to="/privacy-policy"
              className="footer-link"
            >
              Privacy policy
            </Link>
            <Link
              to="/country-profiles/finland"
              className="footer-link"
            >
              Country profiles
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;

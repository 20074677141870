import React, { useContext, useEffect, useRef, useState } from "react";
import "../../../styles/Sidebar.scss";
import { Button, Tooltip } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { ReactComponent as SearchIcon } from "./search-interface-symbol 1.svg";
import { Link } from "react-router-dom";
import Select from "../../../components/FormElements/Select";
import investigationService from "../../../services/InvestigationService";
import { FermiContext } from "../../../App";

function Sidebar({
  selectInvestigation,
  selectedInvestigation,
  investigations,
}) {
  const tranformCreatedAtDate = (created_at) => {
    let date = new Date(created_at);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    const formattedDate = `${day}/${month}/${year}`;
    return formattedDate;
  };

  const createCurrentDateObject = () => {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");

    const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
    return formattedDate;
  };

  const compareDates = (a, b) => {
    let dateStringA = tranformCreatedAtDate(
      a.created_at ? a.created_at : createCurrentDateObject()
    );
    let dateStringB = tranformCreatedAtDate(
      b.created_at ? b.created_at : createCurrentDateObject()
    );
    let [dayA, monthA, yearA] = dateStringA.split("/");
    const dateObjA = new Date(+yearA, +monthA - 1, +dayA);
    let [dayB, monthB, yearB] = dateStringB.split("/");
    const dateObjB = new Date(+yearB, +monthB - 1, +dayB);
    const compareResult = dateObjA - dateObjB;
    return compareResult;
  };

  const searchRef = useRef(null);

  const [data, setData] = useState([]);
  const [sortValue, setSortValue] = useState("recent");
  const [topicValue, setTopicValue] = useState(0);

  const userData = useContext(FermiContext);

  const sortOptions = [
    { name: "recent", title: "Recent" },
    { name: "older", title: "Older" },
  ];

  const topicOptions = [
    { name: "all", title: "All", value: 0 },
    { name: "covid", title: "Covid-19", value: 2 },
    { name: "far-right", title: "Far right wing", value: 3 },
    { name: "far-left", title: "Far left wing", value: 1 },
  ];

  const sortData = () => {
    let searchText = searchRef.current.value;
    searchText = searchText.replace(/\s/g, "").length === 0 ? "" : searchText;
    let tempInvestigations =
      topicValue !== 0
        ? investigations.filter((element) => element.use_case === topicValue)
        : investigations;
    let filteredInvestigations = tempInvestigations.filter((element) =>
      element.title.toLowerCase().includes(searchText)
    );
    if (data.length === 0 || sortValue === "recent") {
      setData(filteredInvestigations.slice().sort(compareDates).reverse());
    } else if (sortValue === "older") {
      setData(filteredInvestigations.slice().sort(compareDates));
    }
  };

  const handleSortChange = (sortType) => {
    setSortValue(sortType.name);
  };

  const handleTopicChange = (topicType) => {
    setTopicValue(topicType.value);
  };

  const getTopicLabel = (useCase) => {
    var topicLabel = "";

    if (useCase) {
      if (useCase === 2) {
        topicLabel = "COVID-19";
      } else if (useCase === 1) {
        topicLabel = "far Left wing";
      } else if (useCase === 3) {
        topicLabel = "far right wing";
      }
    }

    return topicLabel;
  };

  useEffect(() => {
    sortData();
  }, [sortValue, topicValue, investigations]); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    let investigationArray = data.length;
    if (investigationArray > 0) {
      const latestInvestigation = data[0];
      if (
        !selectedInvestigation ||
        selectedInvestigation.investigationID !==
          latestInvestigation.investigationID
      ) {
        selectInvestigation(latestInvestigation);
      }
    } else {
      selectInvestigation(null);
    }
  }, [data]); //eslint-disable-line react-hooks/exhaustive-deps

  const onSearchHandler = (e) => {
    let searchString = e.target.value.toLowerCase();
    let investigationsToCheck =
      topicValue !== 0
        ? investigations.filter((inv) => inv.use_case === topicValue)
        : investigations;
    let searchElements = investigationsToCheck.filter((element) =>
      element.title.toLowerCase().includes(searchString)
    );
    if (searchString.replace(/\s/g, "").length === 0) {
      sortData();
    } else {
      setData(searchElements);
    }
  };

  const onHandleInvestigationClick = (element) => {
    if (element.investigationID) {
      investigationService
        .retrieveInvestigationResults(
          userData.userData.sub,
          element.investigationID
        )
        .then(({ data }) => {
          selectInvestigation(data.investigation_result);
        })
        .catch((error) => {
          selectInvestigation(
            investigations.find(
              (item) => item.investigationID === element.investigationID
            )
          );
        });
    }
  };

  return (
    <div id="sidebar">
      <div className="top-section">
        <h2>List of investigations</h2>

        <Button variant="contained">
          <AddIcon id="icon" />
          <Link to="/create-new-investigation">Create new</Link>
        </Button>

        <div id="search">
          <input
            type="text"
            placeholder="Search"
            onChange={onSearchHandler}
            ref={searchRef}
          />
          <SearchIcon />
        </div>

        <div id="filters">
          <div className="filter">
            <Select
              title="Sort by"
              options={sortOptions}
              handleChange={handleSortChange}
            />
          </div>
          <div className="filter">
            <Select
              title="Topic"
              options={topicOptions}
              handleChange={handleTopicChange}
            />
          </div>
        </div>
      </div>

      <ul>
        {data.map((element, index) => (
          <li
            key={element.investigationID ?? index}
            className={
              element?.investigationID ===
              selectedInvestigation?.investigationID
                ? "active"
                : ""
            }
            onClick={() => onHandleInvestigationClick(element)}
          >
            <div className="labels mister">
              <span>{getTopicLabel(element.use_case)}</span>
              {element.created_at && (
                <>
                  <span style={{ marginInline: "4px" }}>|</span>{" "}
                  <span>{tranformCreatedAtDate(element.created_at)}</span>
                </>
              )}
            </div>
            <Tooltip title={element.title}>
              <div>{element.title}</div>
            </Tooltip>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default Sidebar;

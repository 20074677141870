import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

const informationalData = [
  {
    key: "disinformationAnalysis",
    title: "Disinformation Sources & Spread Analysis",
    content: (
      <div>
        <p>
          The Spread Analyser is a key tool in the FERMI platform, created to
          help law enforcement agents explore the spread of disinformation and
          fake news (D&FN) on social media. It provides a structured way to map
          social media interactions showing how false information spreads in a
          social media network
        </p>
        <p>
          The tool uses advanced techniques like data extraction, data analysis,
          graph analysis, and machine learning (ML) to give deeper insights into
          the network of disinformation. This helps users understand the
          connections and identify patterns in the spread of fake news.
        </p>
        <p>
          Important features of the Spread Analyser include identifying bot
          activity and evaluating the influence of each post. This means it can
          detect automated accounts that spread false information and measure
          how impactful each post is in spreading D&FN.
        </p>
        <p>
          Overall, the Spread Analyser offers a comprehensive view and analysis
          of how disinformation spreads, making it easier for law enforcement to
          track and understand these activities.
        </p>
      </div>
    ),
  },
  {
    key: "sentimentAnalysis",
    title: "Overall sentiment score / Sentiment score over time",
    content: (
      <p>
        This is an analysis of the sentiment of all the posts identified being
        part of the investigation. The sentiment analysis is using machine
        learning techniques on the text of each posts, trying to identify
        whether the sentiment of the user who authored a post was neutral (i.e.
        “The new vaccine was released today”, positive (i.e. “I cannot wait to
        get the new vaccine”) or negative (i.e. “The new vaccine is going to
        poison us all”). The graphs below represent that analysis in total and
        over time, providing insights about the sentiment trends and shifts over
        time, as well as the overall sentiment of the social network captured by
        the tool. These graphs contribute to evaluating the impact of
        disinformation campaigns by identifying and analysing shifts in
        sentiment trends.
      </p>
    ),
  },
  {
    key: "crimePredictor",
    title: "Crimes predictor",
    content: (
      <div>
        <p>
          Using deep learning AI algorithms, we can gain deeper understanding of
          the patterns within data than classic statistical methods can achieve.
          Like the human brain, deep learning algorithms study the information
          humans provide them, in order to{" "}
          <strong>
            comprehend data from different perspectives and perform tasks using
            the knowledge they’ve gained.
          </strong>
        </p>
        <p>
          Across the world, police agencies have begun using AI algorithms to
          help understand the when, where, and who of criminal activities before
          they occur.
          <strong>
            {" "}
            The Dynamic Flows Modeler is built using deep learning algorithms
            that have studied past crime occurrences, the spread of D&FN online,
            and how they move together.
          </strong>
        </p>

        <p>
          When you provide a D&FN post to the platform, its spread through
          social media is traced by the Spread Analyser and provided to the
          Dynamic Flows Modeler. Then,{" "}
          <strong>
            the crime in the weeks leading up to the social media post is
            understood through FERMI’s innovative swarm learning infrastructure{" "}
          </strong>
          that allows the Dynamic Flows Modeler to know how crime has been
          evolving without a participating LEA sacrificing the privacy of their
          data.{" "}
          <strong>
            The Dynamic Flows Modeler then makes a wholistic estimate of future
            levels of crime{" "}
          </strong>
          – considering the spread of the D&FN campaign, seasonal fluctuations
          (e.g., shifts in weather patterns, seasonal variation in public
          behaviour), and pre-existing levels of crime –
          <strong>
            {" "}
            to give FERMI end-users an indication of the direction of crime in
            the period following a D&FN post.
          </strong>
        </p>
        <p>
          Swarm learning is a key feature of the FERMI platform, designed to
          enable law enforcement agencies (LEAs) to collaboratively train AI
          models without sharing sensitive data externally. This approach
          utilizes advanced privacy-preserving techniques, including the
          addition of Gaussian noise to AI model weights, ensuring that data
          privacy and security are maintained by design.
        </p>
        <p>
          The collaboration among LEAs currently takes place indirectly, using
          data previously provided by some agencies from Belgium, Finland, and
          Germany. This method allows for the inference of weekly crime
          statistics at the NUTS-2 level for the period between 2019 to 2023,
          without the need for LEAs to install external frameworks within their
          IT infrastructures—a decision driven by justified security concerns
          expressed by the agencies themselves. However, should LEAs choose to
          undertake a joint investigation in the future by agreeing to install
          an external framework on their premises, they would need to define a
          common problem to solve, provide similar datasets, and set a specific
          target to predict or infer. Additionally, each agency would need to
          provide their own devices, such as laptops or servers, to facilitate
          this secure and collaborative training environment.
        </p>

        <p>
          As an improvement security feature, we are planning to incorporate
          also secure multi- party computation protocols, specifically secure
          sum protocols, to further enhance data confidentiality. This protocol
          allows multiple parties to combine the outputs of their individual
          models into a more robust model without revealing specific model
          weights. Each participant contributes encrypted model updates, which
          are aggregated to update a global model securely.
        </p>

        <p>
          Our platform integrates swarm learning with the dynamics flow modeler
          to enhance public safety and crime prevention efforts effectively. By
          combining the predictive power of the AI model with dynamic modelling
          of information flows, our platform provides a comprehensive tool for
          anticipating and counteracting the ramifications of disinformation
          campaigns on crime occurrences.
        </p>
      </div>
    ),
  },
  {
    key: "communityResilience",
    title: null,
    content: (
      <div>
        <div>
          <h3>Socio-economic Analyser</h3>
          <h4 style={{ textDecoration: "underline" }}>Model</h4>
          <p>
            The socioeconomic analyzer is primarily addressed in task T3.5 and
            examines the impact of extremist crimes on economically measurable
            variables, such as GDP per capita. To quantify this effect, we use
            the following equation:
          </p>
          <p style={{ textAlign: "center" }}>
            GDPpc<sub>rt</sub> = α + β<sub>1</sub> Crime<sub>rt</sub> + β
            <sub>2</sub> X<sub>rt</sub> + vr + ε<sub>rt</sub> (1)
          </p>
          <p>
            where GDPpc<sub>rt</sub> represents the economic welfare level for a
            given region (r) and time period (￿).Crime<sub>rt</sub> is our
            measure of extremism in terms of crime for region (r) at time (t).
            This measure varies based on the political motivation and nature of
            the crime.β<sub>1</sub> is the cost coefficient, indicating how
            one-unit increase in extremist crime impacts economic welfare.X
            <sub>rt</sub> is a vector of control variables. α is the constant v
            <sub>r</sub>, represents region-specific time-invariant
            characteristics.ε<sub>rt</sub> is the error term for the regression.
          </p>

          <p>
            Equation (1) is estimated using the Generalized Method of Moments
            (GMM) Arellano-Bond estimator, which addresses several
            methodological challenges. This estimator is suitable for datasets
            with relatively few time periods and a larger number of individuals,
            such as regions or countries. It also accounts for potential
            endogeneity by allowing explanatory variables to correlate with past
            and current values of the error term.
          </p>

          <p>
            The primary focus of equation (1) is the cost coefficient β
            <sub>1</sub>, which quantifies the impact of extremism. Since the
            analysis considers different types of crime based on their nature
            and political motivation, several coefficients are produced. These
            coefficients are provided to UCSC in a static format to be included
            in the Behavior Profiler, which calculates the costs of predicted
            crimes caused by online disinformation campaigns. This cost is
            computed using the following equation:
          </p>
          <p style={{ textAlign: "center" }}>
            crime cost<sub>rt</sub> = number crime<sub>rt</sub> * population
            <sub>rt</sub> * cost parameter<sub>t</sub>
          </p>
          <p>
            Where crime cost<sub>rt</sub> is the cost of crime in euro for a
            given region (r) and a given time period (t).number crime
            <sub>rt</sub> is the number of crimes as predicted by UCSC in region
            (r) and time (t) while population<sub>rt</sub>
            refers to the number of people in region (r) at time (t). Finally,
            the cost parameter corresponds to the cost coefficient we obtain
            from equation (1)
          </p>
          <h4 style={{ textDecoration: "underline" }}>Results from Germany </h4>
          <p>
            To better explain the Socioeconomic Analyzer component and its
            contribution to the Behaviour Profiler, we present the data used and
            the results from the German pilot. In this pilot, we estimated the
            impact of politically motivated crimes on economic welfare. Economic
            welfare is measured using GDP per capita data for German NUTS2
            regions, sourced from the INKAR dataset, and expressed in thousands
            of euros. To proxy extremist crimes, we use the number of
            politically motivated crimes per 100,000 inhabitants from the PMK
            database. These crimes are classified based on their political
            motivation (e.g., left-wing, right-wing, foreign) and the type of
            offence.
          </p>
          <p>
            The results from estimating equation (1) produced several
            coefficients, categorized by the type of crime and its political
            motivation. Coefficients highlighted in green are statistically
            significant and can be incorporated into the Behaviour Profiler. In
            contrast, coefficients shown in grey or red are not statistically
            significant and should not be used.
          </p>
          {/* Add array here */}
          <div>
            <strong>
              Table 2: the impact of crime on GDP pro capite in Germany
            </strong>
          </div>
          <TableContainer component={Paper}>
            <Table sx={{ width: "100%" }} aria-label="fermi-table">
              <TableHead>
                <TableRow>
                  <TableCell>Offence</TableCell>
                  <TableCell>Motivation</TableCell>
                  <TableCell>Coefficient</TableCell>
                  <TableCell>Remark</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow sx={{ backgroundColor: "#00800059" }}>
                  <TableCell>All PMK</TableCell>
                  <TableCell>all</TableCell>
                  <TableCell>-0.212337</TableCell>
                  <TableCell></TableCell>
                </TableRow>
                <TableRow sx={{ backgroundColor: "#00800059" }}>
                  <TableCell>Theft</TableCell>
                  <TableCell>left</TableCell>
                  <TableCell>-6.670600</TableCell>
                  <TableCell></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Disorderly</TableCell>
                  <TableCell>left</TableCell>
                  <TableCell>not statistically significant</TableCell>
                  <TableCell></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Destruction</TableCell>
                  <TableCell>left</TableCell>
                  <TableCell>not statistically significant</TableCell>
                  <TableCell></TableCell>
                </TableRow>
                <TableRow sx={{ backgroundColor: "#00800059" }}>
                  <TableCell>Assault</TableCell>
                  <TableCell>left</TableCell>
                  <TableCell>-5.498345</TableCell>
                  <TableCell></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Theft</TableCell>
                  <TableCell>right</TableCell>
                  <TableCell>not statistically significant</TableCell>
                  <TableCell></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Disorderly</TableCell>
                  <TableCell>right</TableCell>
                  <TableCell>not statistically significant</TableCell>
                  <TableCell></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Destruction</TableCell>
                  <TableCell>right</TableCell>
                  <TableCell>not statistically significant</TableCell>
                  <TableCell></TableCell>
                </TableRow>
                <TableRow sx={{ backgroundColor: "#00800059" }}>
                  <TableCell>Assault</TableCell>
                  <TableCell>right</TableCell>
                  <TableCell>not statistically significant</TableCell>
                  <TableCell></TableCell>
                </TableRow>
                <TableRow sx={{ backgroundColor: "#00800059" }}>
                  <TableCell>Theft</TableCell>
                  <TableCell>foreign</TableCell>
                  <TableCell>-1.381101</TableCell>
                  <TableCell></TableCell>
                </TableRow>
                <TableRow sx={{ backgroundColor: "#00800059" }}>
                  <TableCell>Disorderly</TableCell>
                  <TableCell>foreign</TableCell>
                  <TableCell>-1.391196</TableCell>
                  <TableCell></TableCell>
                </TableRow>
                <TableRow sx={{ backgroundColor: "#00800059" }}>
                  <TableCell>Destruction</TableCell>
                  <TableCell>foreign</TableCell>
                  <TableCell>-1.312517</TableCell>
                  <TableCell></TableCell>
                </TableRow>
                <TableRow sx={{ backgroundColor: "#00800059" }}>
                  <TableCell>Assault</TableCell>
                  <TableCell>foreign</TableCell>
                  <TableCell>-1.539325</TableCell>
                  <TableCell></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Theft</TableCell>
                  <TableCell>religious</TableCell>
                  <TableCell>not statistically significant</TableCell>
                  <TableCell></TableCell>
                </TableRow>
                <TableRow sx={{ backgroundColor: "#ff000052" }}>
                  <TableCell>Disorderly</TableCell>
                  <TableCell>religious</TableCell>
                  <TableCell>-30050.91000</TableCell>
                  <TableCell>huge coefficient - weird, do not use</TableCell>
                </TableRow>
                <TableRow sx={{ backgroundColor: "#ff000052" }}>
                  <TableCell>Destruction</TableCell>
                  <TableCell>religious</TableCell>
                  <TableCell>64.3053900</TableCell>
                  <TableCell>
                    even hugher and weirder, and on top positive sign - weird,
                    do not use
                  </TableCell>
                </TableRow>
                <TableRow sx={{ backgroundColor: "#00800059" }}>
                  <TableCell>Assault</TableCell>
                  <TableCell>religious</TableCell>
                  <TableCell>-6.327422</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <p>
            The interpretation of these coefficients is as follows: for each
            additional politically motivated offence, GDP per capita, measured
            in thousands of euros, is reduced by the value of the respective
            coefficient. For example, a right-wing politically motivated assault
            has a coefficient of -3.007005. This implies that one additional
            such assault per 100,000 inhabitants (as offences are normalized per
            100,000 inhabitants) in a NUTS-2 region with exactly 100,000
            inhabitants is estimated to reduce GDP per capita by approximately
            3.000 euros over the course of a year.
          </p>
          <p>
            As previously mentioned, these coefficients are integrated into the
            Behaviour Profiler through equation (2). However, the equation must
            be adapted to align with the data and predictions used. First, the
            frequency of offences must be accounted for. While UCSC predicts the
            absolute number of crimes, the actual number of crimes used in
            equation (1) is expressed as a frequency. To adjust for this
            difference and the variation in population sizes across NUTS-2
            regions, we scale the predicted number of crimes by dividing it by
            the region’s population and multiplying by 100,000. Additionally,
            since the estimated coefficients are based on GDP per capita
            measured in thousands of euros (as provided in the INKAR database),
            the cost parameter must also be multiplied by 1,000.
          </p>
          <p>
            Second, the time window must be adjusted. The cost coefficients
            estimate annual impacts on GDP, whereas UCSC predicts crimes over
            two-week periods. To align these timeframes, the coefficients are
            divided by 26, as a year comprises approximately 52 weeks and the
            two-week prediction period represents half of this frequency. This
            scaling ensures the coefficients match the temporal resolution of
            the predictions. Therefore, equation (2) can be rewritten in this
            form:
          </p>
          <p style={{ textAlign: "center" }}>
            crime cost<sub>rt</sub> = number crime<sub>rt</sub> * ({" "}
            <sup>100,000</sup>&frasl;
            <sub>
              population<sub>rt</sub>
            </sub>{" "}
            ) * cost parameter<sub>t</sub> * <sup>1,000</sup>&frasl;
            <sub>26</sub>
          </p>
        </div>
        <div>
          <h3>Description of UI Community Resilience Management Modeler</h3>
          <p>
            The Community Resilience Management Modeler is a socio-technical
            component that integrates diverse inputs, particularly from
            socio-technical partners such as LEAs. The component adopts a
            comprehensive approach to risk management, utilising a
            prioritisation risk scale. The scale evaluates two critical
            dimensions: the severity of crimes associated with D&FN under
            investigation and the likelihood of these crimes occurring.
          </p>
          <p>
            These two primary factors are further enhanced by incorporating a
            detailed behavioural profile and a socioeconomic analysis, which
            generate an impact index. This index serves as a vital input to the
            component, reflecting the potential consequences and urgency of the
            situation. Once computed, the index is normalised to ensure
            consistency and then fed into the component for subsequent
            processing.
          </p>
          <p>
            The system employs an if clause mechanism to assess the impact
            level. An additional layer of decision-making is activated for
            instances classified as having a high or extremely high impact. In
            the context of the pilot phase, the threshold was deliberately
            adjusted to include cases of medium impact, ensuring a broader
            evaluation spectrum. The component triggers the Multi-Criteria
            Decision Analysis (MCDA) model upon meeting the specified threshold.
            This sophisticated framework selects three optimal countermeasures
            to address the identified risks effectively.
          </p>
          <p>
            This procedural framework aligns with the globally recognised ISO
            31000 standard for risk management, underscoring its compliance with
            industry best practices. Integrating technical, behavioural, and
            socioeconomic data, the Community Resilience Management Modeler
            manages complex disinformation and fake news risk investigations,
            ensuring actionable and evidence-based countermeasure selection.
          </p>
        </div>
      </div>
    ),
  },
];

export default informationalData;

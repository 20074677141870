import React, { useContext, useEffect, useState } from "react";
import "../../styles/LandingPage.scss";
import NodeGraph from "../../components/NodeGraph/NodeGraph";
import Sidebar from "./Sidebar/Sidebar";
import SelectedInvestigation from "./SelectedInvestigation/SelectedInvestigation";
import SentimentGraph from "../../components/SentimentGraph/SentimentGraph";
import OverallSentimentScore from "../../components/OverallSentimentScore/OverallSentimentScore";
import MapChart from "../../components/MapChart/MapChart";
import TweetImpact from "./TweetImpact/TweetImpact";
import Recommendations from "../../components/Recommendations/Recommendations";
import InvestigationService from "../../services/InvestigationService";
import { FermiContext } from "../../App";
import Loader from "../../components/Utilities/Loader/Loader";
import PopUp from "../../components/PopUp/PopUp";
import { Alert, Button } from "@mui/material";
import { Link } from "react-router-dom";
import InfoModal from "../../components/Modal/InfoModal";
import informationalData from "./InformationalData";

function LandingPage() {
  const [selectedInvestigation, setSelectedInvestigation] = useState(null);
  const [investigations, setInvestigations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [noInvestigationPopUp, setNoInvestigationPopUp] = useState(false);
  const [reloadInvestigations, setReloadInvestigations] = useState(false);

  const [infoModalOpen, setInfoModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState({ title: "", content: "" });

  const userData = useContext(FermiContext);

  const openInfoModal = (key) => {
    const info = informationalData.find((item) => item.key === key);
    if (info) {
      setModalContent({ title: info.title, content: info.content });
      setInfoModalOpen(true);
    }
  };

  const closeInfoModal = () => {
    setInfoModalOpen(false);
  };

  //load all user's investigations
  useEffect(() => {
    setLoading(true);
    if (userData.userData && userData.userData.sub) {
      InvestigationService.retrieveUserInvestigations(userData.userData.sub)
        .then((response) => {
          setInvestigations(response.data.investigations);
          setNoInvestigationPopUp(response.data.investigations.length <= 0);
          setSelectedInvestigation(
            response.data.investigations.length > 0
              ? response.data.investigations[0]
              : null
          );
        })
        .catch((error) => {
          setInvestigations([]);
          setNoInvestigationPopUp(true);
          setSelectedInvestigation(null);
        })
        .finally(() => {
          setLoading(false);
          setReloadInvestigations(false);
        });
    }
  }, [userData.userData, reloadInvestigations]);

  const selectInvestigation = (investigation) => {
    setSelectedInvestigation(investigation);
  };

  return (
    <div className="landing-page-wrapper page-container">
      <Sidebar
        investigations={investigations}
        selectInvestigation={selectInvestigation}
        selectedInvestigation={selectedInvestigation}
      />
      <div id="content">
        {loading ? (
          <Loader />
        ) : (
          <>
            {selectedInvestigation && (
              <section>
                <h2>Selected Investigation</h2>
                <SelectedInvestigation
                  investigation={selectedInvestigation}
                  onRefresh={setReloadInvestigations}
                />
              </section>
            )}

            {selectedInvestigation &&
              !selectedInvestigation.graph &&
              !selectedInvestigation.crimes &&
              !selectedInvestigation.impact &&
              !selectedInvestigation.countermeasures && (
                <Alert severity="info">Results are not ready yet.</Alert>
              )}

            {selectedInvestigation !== null && selectedInvestigation?.graph && (
              <>
                <section>
                  <h2>Investigation Details</h2>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <h3 style={{ margin: 0 }}>
                      Disinformation Sources & Spread Analysis
                    </h3>
                    <Button
                      variant="outlined"
                      size="small"
                      style={{
                        minWidth: "2px",
                        width: "25px",
                        marginLeft: "10px",
                        padding: "5px",
                        color: "white",
                        backgroundColor: "#00556F",
                        borderRadius: "50%",
                        lineHeight: "0.75",
                      }}
                      onClick={() => openInfoModal("disinformationAnalysis")}
                    >
                      i
                    </Button>
                  </div>
                  <NodeGraph
                    nodeData={
                      selectedInvestigation.graph.node_graph ??
                      selectedInvestigation.graph
                    }
                    tweetId={selectedInvestigation.tweet ?? ""}
                  />
                </section>
                <section>
                  <div id="sentiment-section">
                    <div id="overall">
                      <h3>Overall sentiment score</h3>
                      <OverallSentimentScore
                        nodes={
                          selectedInvestigation.graph.node_graph ??
                          selectedInvestigation.graph
                        }
                      />
                    </div>
                    <div id="overtime">
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <h3 style={{ margin: 0 }}>Sentiment score over time</h3>
                        <Button
                          variant="outlined"
                          size="small"
                          style={{
                            minWidth: "2px",
                            width: "25px",
                            marginLeft: "10px",
                            padding: "5px",
                            color: "white",
                            backgroundColor: "#00556F",
                            borderRadius: "50%",
                            lineHeight: "0.75",
                          }}
                          onClick={() => openInfoModal("sentimentAnalysis")}
                        >
                          i
                        </Button>
                      </div>
                      <SentimentGraph
                        nodes={
                          selectedInvestigation.graph.node_graph.nodes ??
                          selectedInvestigation.graph.nodes
                        }
                      />
                    </div>
                  </div>
                </section>
              </>
            )}

            {selectedInvestigation && selectedInvestigation?.crimes && (
              <section>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <h3 style={{ margin: 0 }}>Crimes predictor </h3>
                  <Button
                    variant="outlined"
                    size="small"
                    style={{
                      minWidth: "2px",
                      width: "25px",
                      marginLeft: "10px",
                      padding: "5px",
                      color: "white",
                      backgroundColor: "#00556F",
                      borderRadius: "50%",
                      lineHeight: "0.75",
                    }}
                    onClick={() => openInfoModal("crimePredictor")}
                  >
                    i
                  </Button>
                </div>
                <MapChart data={selectedInvestigation.crimes} />
              </section>
            )}

            {selectedInvestigation &&
              (selectedInvestigation?.impact ||
                selectedInvestigation?.countermeasures) && (
                <section>
                  <div id="country-profiles-wrapper">
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <h3 style={{ margin: 0 }}>
                        Proposed Countermeasures for Community Resilience{" "}
                      </h3>
                      <Button
                        variant="outlined"
                        size="small"
                        style={{
                          minWidth: "2px",
                          width: "25px",
                          marginLeft: "10px",
                          padding: "5px",
                          color: "white",
                          backgroundColor: "#00556F",
                          borderRadius: "50%",
                          lineHeight: "0.75",
                        }}
                        onClick={() => openInfoModal("communityResilience")}
                      >
                        i
                      </Button>
                    </div>
                    <Button variant="contained">
                      <Link to="/country-profiles" target="_blank">
                        Country profiles
                      </Link>
                    </Button>
                  </div>

                  <div id="recommendations-section">
                    {selectedInvestigation?.impact && (
                      <div id="tweet-impact">
                        <TweetImpact impact={selectedInvestigation.impact} />
                      </div>
                    )}
                    {selectedInvestigation?.countermeasures && (
                      <div id="countemeasures">
                        <Recommendations
                          countermeasures={
                            selectedInvestigation.countermeasures
                              .countermeasures
                          }
                        />
                      </div>
                    )}
                  </div>
                </section>
              )}
          </>
        )}
      </div>
      <PopUp
        title="No available investigation results"
        openPopUp={noInvestigationPopUp}
        showCloseButton={false}
      >
        <Alert severity="warning">
          There are no investigation data available. You can create a new
          invetsigation by clicking{" "}
          <Link to="/create-new-investigation">here</Link>.
        </Alert>
      </PopUp>
      <InfoModal
        open={infoModalOpen}
        onClose={closeInfoModal}
        title={modalContent.title}
        content={modalContent.content}
      />
    </div>
  );
}

export default LandingPage;

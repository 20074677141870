import {
  Alert,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Slider,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import "../../styles/CreateNewInvestigation.scss";
import { Button } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import MultiSelect from "../../components/FormElements/MultiSelect";
import { Link, useNavigate } from "react-router-dom";
import investigationService from "../../services/InvestigationService";
import { FermiContext } from "../../App";
import InfoModal from "../../components/Modal/InfoModal";

function CreateNewInvestigation() {
  const navigate = useNavigate();
  const { userData } = useContext(FermiContext);
  const [formData, setFormData] = useState({
    title: "",
    country: [],
    use_case: "",
    tweet: "",
    users: [],
    spread_breadth: 5,
    spread_depth: 5,
  });
  const [alert, setAlert] = useState(null);
  const [infoModalOpen, setInfoModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState({ title: "", content: "" });

  const countries = [
    { label: "Please select a country", value: "", disabled: true },

    { label: "Germany", value: "DE" },

    { label: "Belgium", value: "BE" },

    { label: "Finland", value: "FI" },
  ];

  const informationalData = [
    {
      key: "title",
      title: "Title",
      content: (
        <p>
          Please provide a title for the investigation which will help you
          identify it.
        </p>
      ),
    },
    {
      key: "useCase",
      title: "Use Case",
      content: (
        <p>
          Please select one of the use cases that is currently supported by the
          FERMI platform.
        </p>
      ),
    },
    {
      key: "postUrl",
      title: "Identified D&FN Post",
      content: (
        <p>
          Please provide the URL of a post from X (former Twitter) or Mastodon
          which will be used as the initial post for the investigation.
        </p>
      ),
    },
    {
      key: "country",
      title: "Country",
      content: (
        <p>
          Please select the country for which the crime and impact analysis
          should be performed.
        </p>
      ),
    },
    {
      key: "breadthAndDepth",
      title: "Breadth & Depth",
      content: (
        <p>
          Breadth determines the number of posts directly connected to the
          initial post which will be investigated, whereas the Depth determines
          up to how many posts afar from the initial post can be collected.
          Increasing any of them will yield more results, but it will require
          more time.
        </p>
      ),
    },
  ];

  const openInfoModal = (key) => {
    const info = informationalData.find((item) => item.key === key);
    if (info) {
      setModalContent({ title: info.title, content: info.content });
      setInfoModalOpen(true);
    }
  };

  const closeInfoModal = () => {
    setInfoModalOpen(false);
  };

  useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
      users: [userData.sub],
    }));
  }, [userData]);

  const handleSubmit = (event) => {
    event.preventDefault();

    const tweetUrl = formData.tweet.trim();
    if (
      !tweetUrl.includes("https://x.com/") &&
      !tweetUrl.includes("https://mastodon.social/")
    ) {
      setAlert({
        type: "error",
        message: "Please provide an X or a Mastodon post",
      });
      return;
    }

    if (
      formData.title.replace(/\s/g, "") !== "" &&
      formData.use_case.replace(/\s/g, "") !== "" &&
      formData.users.length !== 0
    ) {
      setAlert(null);

      let source = "";
      if (tweetUrl.includes("x.com")) {
        source = "twitter";
      } else if (tweetUrl.includes("mastodon.social")) {
        source = "mastodon";
      }

      const lastIndex = tweetUrl.lastIndexOf("/");
      const tweetId = tweetUrl.slice(lastIndex + 1);

      if (Number(tweetId)) {
        investigationService
          .createInvestigation({
            ...formData,
            tweet: tweetId,
            source: source,
            use_case: Number(formData.use_case),
            created_at: createInvestigationDate(),
          })
          .then((response) => {
            setAlert({
              type: "success",
              message: "Investigation was successfully submitted!",
            });
            setTimeout(function () {
              navigate("/");
            }, 2000);
          })
          .catch((error) => {
            setAlert({
              type: "error",
              message:
                "There was a problem submitting your Investigation data!",
            });
          });
      } else {
        setAlert({
          type: "error",
          message: "Please provide tweet URl that contains tweet id!",
        });
      }
    } else {
      setAlert({
        type: "warning",
        message: "You should fill in all the necessary fields!",
      });
    }
  };

  const handleDiscard = (event) => {
    event.preventDefault();
    setFormData({
      title: "",
      country: [],
      use_case: "",
      tweet: "",
      users: [],
    });
    setAlert(null);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    if (["users"].includes(name)) {
      setFormData((prevData) => ({
        ...prevData,
        [name]: typeof value === "string" ? value.split(",") : value,
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const createInvestigationDate = () => {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");

    const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
    return formattedDate;
  };

  return (
    <div id="new-investigation-wrapper">
      <form>
        <h1>Add Investigation</h1>
        <FormControl fullWidth>
          <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
            <TextField
              label="Title"
              name="title"
              value={formData.title}
              onChange={handleChange}
              fullWidth
              required
              placeholder="Provide here a title for the new investigation"
            />
            <Button
              variant="outlined"
              size="small"
              style={{
                minWidth: "2px",
                width: "25px",
                marginLeft: "10px",
                padding: "5px",
                color: "white",
                backgroundColor: "#00556F",
                borderRadius: "50%",
                lineHeight: "0.75",
              }}
              onClick={() => openInfoModal("title")}
            >
              i
            </Button>
          </div>
        </FormControl>
        <FormControl fullWidth>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
            }}
          >
            <MultiSelect
              id={"country"}
              label={"Please select a country"}
              items={countries}
              selectedItems={formData.country}
              handleChange={(e) =>
                setFormData((prevData) => ({
                  ...prevData,
                  country: e.target.value,
                }))
              }
              fullWidth
              isMultiSelect={false}
            />
            <Button
              variant="outlined"
              size="small"
              style={{
                minWidth: "2px",
                width: "25px",
                marginLeft: "10px",
                padding: "5px",
                color: "white",
                backgroundColor: "#00556F",
                borderRadius: "50%",
                lineHeight: "0.75",
              }}
              onClick={() => openInfoModal("country")}
            >
              i
            </Button>
          </div>
        </FormControl>

        <FormControl>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <FormLabel id="investigation-use_case">
              Select the investigation use case:
            </FormLabel>
            <Button
              variant="outlined"
              size="small"
              style={{
                minWidth: "2px",
                width: "25px",
                marginLeft: "10px",
                padding: "5px",
                color: "white",
                backgroundColor: "#00556F",
                borderRadius: "50%",
                lineHeight: "0.75",
              }}
              onClick={() => openInfoModal("useCase")}
            >
              i
            </Button>
          </div>
          <RadioGroup
            aria-labelledby="investigation-use_case"
            name="use_case"
            onChange={handleChange}
          >
            <FormControlLabel
              value={3}
              control={<Radio />}
              label="Political interference from extremists on the Far-right wing"
            />
            <FormControlLabel
              value={2}
              control={<Radio />}
              label="Health crisis: riots and different forms of violence (COVID-19)"
            />
            <FormControlLabel
              value={1}
              control={<Radio />}
              label="Extremist propaganda socio-political destabilization and real terrorist attacks from the Far-left wing"
            />
          </RadioGroup>
        </FormControl>
        <FormControl fullWidth>
          <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
            <TextField
              label="Identified D&FN post"
              name="tweet"
              value={formData.tweet}
              onChange={handleChange}
              fullWidth
              required
              placeholder="Provide here the URL of the identified D&FN post (eg. https://twitter.com/kolowe/status/16825641454813994)"
            />
            <Button
              variant="outlined"
              size="small"
              style={{
                minWidth: "2px",
                width: "25px",
                marginLeft: "10px",
                padding: "5px",
                color: "white",
                backgroundColor: "#00556F",
                borderRadius: "50%",
                lineHeight: "0.75",
              }}
              onClick={() => openInfoModal("postUrl")}
            >
              i
            </Button>
          </div>
        </FormControl>

        <FormControl>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "8px",
            }}
          >
            <FormLabel id="investigation-use_case">
              Select investigation's breadth & depth:
            </FormLabel>
            <Button
              variant="outlined"
              size="small"
              style={{
                minWidth: "2px",
                width: "25px",
                marginLeft: "10px",
                padding: "5px",
                color: "white",
                backgroundColor: "#00556F",
                borderRadius: "50%",
                lineHeight: "0.75",
              }}
              onClick={() => openInfoModal("breadthAndDepth")}
            >
              i
            </Button>
          </div>
          <div style={{ display: "flex", gap: "16px", width:"100%" }}>
            <div style={{width:"40%"}}>
              <FormLabel id="investigation-use_case">Breadth</FormLabel>
              <Slider
                aria-label="Breadth"
                max={6}
                min={1}
                step={1}
                marks
                valueLabelDisplay="auto"
                value={formData.spread_breadth}
                onChange={handleChange}
                name="spread_breadth"
              ></Slider>
            </div>
            <div style={{width:"40%"}}>
              <FormLabel id="investigation-use_case">Depth </FormLabel>
              <Slider
                aria-label="Depth"
                max={6}
                min={1}
                step={1}
                marks
                valueLabelDisplay="auto"
                value={formData.spread_depth}
                name="spread_depth"
                onChange={handleChange}
              ></Slider>
            </div>
          </div>
        </FormControl>

        {alert && <Alert severity={`${alert.type}`}>{alert.message}</Alert>}

        <FormControl>
          <div className="form-buttons">
            <Button
              variant="contained"
              onClick={handleDiscard}
              id="whiteButton"
            >
              <Link to="/">Cancel</Link>
            </Button>
            <Button variant="contained" onClick={handleSubmit}>
              Create investigation
            </Button>
          </div>
        </FormControl>
      </form>
      <InfoModal
        open={infoModalOpen}
        onClose={closeInfoModal}
        title={modalContent.title}
        content={modalContent.content}
      />
    </div>
  );
}

export default CreateNewInvestigation;

import axios from "axios";
import {
  baseUrl,
  deleteOptions,
  getOptions,
  postOptions,
  putOptions,
} from "../config";

class InvestigationService {
  constructor(baseUrl) {
    this.basePath = "";
    this.baseUrl = `${baseUrl}${this.basePath}`;
  }

  createInvestigation(data = {}) {
    return axios(postOptions(this.baseUrl, "/new-investigation", data));
  }

  deleteInvestigation(userId, investigationId) {
    return axios(
      deleteOptions(this.baseUrl, `/investigation/${userId}/${investigationId}`)
    );
  }

  updateInvestigation(userId, investigationId) {
    return axios(
      putOptions(this.baseUrl, `/update-investigation/${userId}/${investigationId}`)
    );
  }

  retrieveUserInvestigations(userId) {
    return axios(getOptions(this.baseUrl, `/investigation/${userId}`));
  }

  retrieveInvestigationResults(userId, investigationId) {
    return axios(
      getOptions(
        this.baseUrl,
        `/investigation-results/${userId}/${investigationId}`
      )
    );
  }
}

const investigationService = new InvestigationService(baseUrl);

export default investigationService;

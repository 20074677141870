import React, { useEffect } from "react";
import "../../styles/Privacy.scss";
import { Link } from "react-router-dom";
function Privacy() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="privacy-policy">
      <h1 className="center">Privacy</h1>
      <h3 className="center underlined">
        FOR PARTICIPANTS PARTAKING IN THE USE OF THE FERMI PLATFORM IN SUPPORT
        OF THE HORIZON EUROPE FERMI PROJECT (Project 101073980)
      </h3>

      <div className="section">
        <h4>Introduction</h4>
        <p>
          You have been invited to use the project platform in the scope of the
          EU-funded Horizon Europe project FERMI (Fake nEws Risk MItigator).
          This document intends to provide you with detailed information
          concerning the platform use, in order for you to be able to take an
          informed decision on whether or not to participate.
        </p>
        <p>
          Before making a decision, it is important that you clearly understand
          the purpose of the research and what it would imply for you. As such,
          please take time to read this document carefully and ask the platform
          administrators all the questions you may have so you can be completely
          sure that you understand all the proceedings of the research,
          including any potential risks and benefits it may entail.
        </p>
        <p>
          This information document may include terms or concepts that you do
          not fully understand. If this is the case, please ask the platform
          administrators to fully explain it or clarify pieces of information.
        </p>
        <p>
          At all times during the foreseen activities and afterwards, the FERMI
          consortium assures the compliance with the relevant national and
          European legislation.
        </p>
      </div>

      <div className="section">
        <h4>Project and research ambitions</h4>
        <p>
          FERMI is an Horizon Europe project that studies and attempts to
          counter the root causes, spread and implications of fake news &
          disinformation.
        </p>
        <p>
          This information sheet informs you about the details and implications
          of using the FERMI platform’s different tools and the platform’s
          compliance with legal and ethics standards.
        </p>
      </div>
      <div className="section">
        <h4>Your participation</h4>
        <p>
          Your participation in the activity described below would be highly
          valuable to meet the project’s research ambitions. By using the
          platform you freely consent to having your data processed, which is
          the legal basis for taking part in the activity and for collecting and
          processing the data you and other participants will be kindly asked to
          provide.
        </p>
      </div>
      <div className="section">
        <h4>Taking part in using the platform is voluntary</h4>
        <p>
          You are free to withdraw your participation from this activity at any
          time without providing justification, upon request to the FERMI
          support channel. No consequences will follow your withdrawal. Any
          personal data of yours that might still be attributable to you such as
          Name, email and Organization name that are required for the
          registration to the FERMI platform will be immediately deleted. Any
          relevant files and hard copies will be destroyed.
        </p>
      </div>
      <div className="section">
        <h4>Using the Platform, including Interacting with an AI system</h4>

        <p>
          Users will be asked to interact with the platform’s different tools,
          which includes the following AI components:
        </p>

        <ul>
          <li>
            Disinformation sources, spread and impact analyser (identifies
            whether a social media account is operated by a real person or a bot
            and grasps the spread of social media messages);
          </li>
          <li>
            Dynamic flows modeler – Crimes Impact Predictor (predicts the crime
            landscape as facilitated by D&FN campaigns by identifying the type,
            time and location of crimes);
          </li>
          <li>
            Swarm learning framework (supports the prediction of the crime
            landscape and facilitates the exchange of non-public data from
            different LEAs) and the
          </li>
          <li>
            Sentiment analysis module (assesses whether social media messages
            that are of concern to the threat analysis are positive, negative or
            neutral).
          </li>
        </ul>
      </div>
      <div className="section">
        <h4>How the AI tools make decisions</h4>
        <p>
          The Disinformation sources, spread and impact analyser implements
          advanced Deep Learning techniques in order to extract concrete
          insights. The primary focus of the AI component will be on identifying
          the central influencer node within the disinformation spreading
          cluster and discern their authenticity as humans or bots. Regarding
          the former, the engagement level of the account will be analysed,
          taking into account factors such as likes, replies, and retweets
          count. All these organic metrics will play a pivotal role in
          generating an influence index for each participant. Concerning the
          latter, account-level insights will be gathered, including account
          activity and followers-to-following ratio. The outcome will be a
          probability rate assigned to the investigated account, indicating how
          likely it is to be a bot.
        </p>
        <p>
          The Dynamic flows modeler – Crimes Impact Predictor makes decisions
          based on the knowledge acquired by the algorithm during the algorithm
          training, that is, the weights of the better performing machine
          learning algorithm among the tested ones. These weights are adjusted
          depending on crime, demographic, social, economic data and
          disinformation and fake news coming from previous academic studies,
          which feeds the system (i.e., location, time, type of crime).
        </p>
        <p>
          The Swarm learning framework’s decisions are also informed by the
          knowledge acquired by the algorithm during algorithm training, that
          is, the weights of the neural network (or other machine learning
          algorithm). These weights are adjusted depending on crime data from
          the European LEAs which feeds the system (location, time, type of
          crime, etc.). The logic behind neural networks imitates the way in
          which the human brain works, how the neurons transmit the information
          between them, etc.
        </p>
        <p>
          And lastly, the Sentiment analysis module uses social media data by
          harnessing the power of the cutting-edge Bidirectional Encoder
          Representations from Transformers (BERT) language model to delve into
          the vast realm of Twitter data (e.g. twitter posts’ data graphs with
          highly influential nodes spreading D&FN). Through a seamless process,
          this module unveils the sentiments embedded within these textual
          treasures. The process involves the following steps: (1) Text
          preprocessing: Text preprocessing sets the stage by meticulously
          cleansing the posts of any extraneous noise, bidding farewell to
          trivial elements like stopwords, punctuation, and even emojis. The
          focus is placed solely on the crucial essence of the text; (2) BERT
          Encoding & Fine- tuning: The BERT Encoding & Fine-tuning employs the
          power of a pre-trained BERT model to transmute each word and subword
          of the Twitter posts into a rich, multi-dimensional vector
          representation. These vectors capture the very essence of context,
          enabling a nuanced understanding of sentiment. Further, the model
          undergoes fine-tuning, meticulously adjusting its parameters to
          optimise performance specifically for sentiment classification in the
          domain of crime investigation. (3) Sentiment Classification: The
          BERT-based model classifies the sentiment of the posts by taking the
          encoded representations of the input text and applying classification
          layers on top of BERT to predict the sentiment label or sentiment
          scores. The model’s architecture allows it to consider the contextual
          relationships between words and phrases in the text, improving its
          ability to capture nuanced sentiment and (4) Sentiment Analysis
          Output: The module provides the output of sentiment analysis based on
          the BERT model’s predictions and it reveals sentiment labels such as
          positive, negative, or neutral. The underlying logic behind these
          predictions is grounded in the ability to encompass a wide spectrum,
          from the overall sentiment score of analysed data graphs to sentiment
          scores over time and even the sentiment score per graph’s node.
        </p>
      </div>
      <div className="section">
        <h4>Data Collection and Processing</h4>
        <p>
          As mentioned above, this research is carried out under the HORIZON
          Europe project FERMI which processes personal data for the purposes of
          examining and countering the root causes, spread and implications of
          fake news & disinformation. Again, data processing takes place amidst
          the effort to use the FERMI platform, including its AI tools. The
          FERMI research activities will take place between 01 October 2022 and
          30 September 2025.
        </p>
        <p>
          We do not plan to collect personal data other than the data required
          for the registration to the FERMI platform (Full name and email)
        </p>
        <p>
          Information that could identify participants will be removed from the
          dataset prior to being analysed for any of the project’s research
          activities.
        </p>
        <p>
          If you change your mind, you can withdraw your consent at any time
          during or after the activity by reaching out to the responsible
          research coordinator(s) and/or the Data Protection Officer (contact
          details are given at the end of this document).
        </p>
        <p>
          We will only collect and process the minimum amount of information
          that is required to achieve the purpose of the FERMI platform. We will
          apply appropriate security measures during all processing and storage
          of your data (see below). All data collection and processing take
          place in strict accordance with the EU General Data Protection
          Regulation 2016/679. You’ll be able to exercise all of your rights as
          a user in accordance with the EU General Data Protection Regulation
          2016/679, which we fully respect and comply with; these are also
          described in this privacy form.
        </p>
        <p>
          No automated decision-making or profiling in accordance with Articles
          22(1) and (4) of the General Data Protection Regulation 2016/679 is
          going to take place.
        </p>
      </div>
      <div className="section">
        <h4>
          Will personal data be used for future research or shared with others?
        </h4>
        <p>
          We do not plan to share any personal data with anybody or use it for
          further research. If otherwise, the information will not be shared
          without your consent.
        </p>
      </div>
      <div className="section">
        <h4>Will personal data be stored?</h4>
        <p>
          Personal data (Full name and email) will be stored to the system which
          are only accessible to the administrator of the FERMI platform.
        </p>
        <p>
          The data will be deleted from all storage media after the end of the
          project. No further processing will be conducted.
        </p>
      </div>
      <div className="section">
        <h4>Who may access my information?</h4>
        <p>
          We will disclose your information if the European Commission, who is
          funding this research, requests information for auditing purposes or
          to evaluate our procedures.
        </p>
        <p>
          Within the consortium partners, your information will only be
          accessible on a need-to-know basis.
        </p>
      </div>
      <div className="section">
        <h4>Your key data protection rights</h4>
        <p>
          In accordance with principles of research ethics and the EU data
          protection framework, you have rights regarding how your personal data
          is processed. Here are your rights and how we can fulfil them:
        </p>
        <ul>
          <li>
            Right to access personal data processed about you, and the right for
            these data to be in a portable form – If you request access to
            personal data that we hold about you, we will provide you with these
            data in an easily accessible format
          </li>
          <li>
            Right to rectify personal data held about you – If you think the
            personal data that we hold about you is inaccurate or incomplete,
            you can request us to correct or complete your personal data.
          </li>
          <li>
            Right to restrict the processing of your personal data – If you want
            to restrict the way we process your personal data, you can request
            that we do so.
          </li>
          <li>
            Right to request that your personal data is erased – If you want us
            to delete your personal data from our systems, you can request that
            we do so.
          </li>
          <li>
            Right to leave the research activity – If you wish to withdraw from
            participating in using the platform, you can do so at any time
            without negative consequences and your personal data will not be
            processed.
          </li>
          <li>
            Right to be informed about the purpose of the data collection, the
            use of the data and storage of the provided data.
          </li>
          <li>
            Right to request that we transfer your personal data to another
            organisation.
          </li>
          <li>
            Right to complain to a supervisory authority – If you feel we have
            not adequately dealt with your requests, you can complain to the
            national data protection authority. This research activity is
            managed by INTRA , and you can find information on the national data
            protection agency as follows: National Commission for Data
            Protection <br /> (Commission Nationale pour la Protection des
            Données - CNPD)
            <br /> 15, Boulevard du Jazz <br />
            L-4370 Belvaux <br />
            Tél. : (+352) 26 10 60 -1
          </li>
          <li>
            Right to lodge a complaint to the project coordinator and/or the
            Data Protection Officer (DPO) (contact below).
          </li>
        </ul>

        <p>
          Requests to exercise these rights will be handled without undue delay.
          If requests to exercise these rights are excessive, malicious,
          impossible to fulfil, or require a disproportionate effort, we may
          reject some requests in accordance with European and national data
          protection legislation.
        </p>
      </div>
      <div className="section">
        <h4>
          What risks will I face by taking part in this study? What will be done
          to protect me against such risks?
        </h4>
        <p>
          Risks of discomfort resulting from this research may include the
          confrontation with controversial political proceedings and the use of
          complex technological tools that are hard to handle. In principle,
          risk of breach of confidentiality in the handling of your personal
          data may lead to personal data breaches. Because the platform collects
          information about you, one of the risks of this research is a loss of
          confidentiality.
        </p>
        <p>
          More specifically, with respect to the above-mentioned AI tools risks
          and limitations are listed as follows:
        </p>
        <ul>
          <li>
            The Disinformation sources, spread and impact analyser does not
            enable the end-user to directly access personal information of the
            person behind an account. As far as the risks are concerned, the
            tool may exhibit biases based on the quality of data used during the
            training phase of the development. Additionally, in scientific
            literature different metrics configurations for evaluating influence
            are proposed with promising outcomes but usually with inadequate
            bias investigation. Since the model development is influenced by the
            scientific literature some bias may be inherited. A similar approach
            will be taken for developing the bot classification model. In bot
            detection there is a risk of incorrectly classifying users
            demonstrating similar behaviour to bots and the possibility of bots
            evolving mechanisms to avoid detection.
          </li>
          <li>
            The Dynamic flows modeler – Crimes Impact Predictor will be limited
            to predicting the type of criminal offences in a given time and
            location and not include further information on the circumstances of
            the crimes at stake. Despite the consortium’s best efforts to
            mitigate and, if possible, to avoid negative consequences of AI,
            there are also some remaining risks that will come with the tool.
            The first AI-centred risk lies in the tool's potential for making
            erroneous predictions. The models utilised by the tool are trained
            on historical data, meaning that predictions are based on
            identifying patterns in the context of past events. For example, a
            disinformation campaign on a specific topic may be correlated with
            an increase in a particular type of crime. However, if the
            relationship between events changes over time due to factors not
            included in the model (e.g., changes in individuals' attitudes
            toward a specific topic), the model may produce biased predictions
            and provide misleading information to end-users. The second main
            AI-centred risk is the potential for discrimination against
            socio-ethnic groups. AI models can inherit biases from the data they
            are trained on, resulting in discriminatory outcomes. If historical
            crime data contains biases or reflects systemic inequalities,
            predictive models may perpetuate and amplify those biases, leading
            to unfair targeting of disadvantaged communities.
          </li>
          <li>
            The main limitation of the Swarm learning framework is the need to
            have at least three LEAs in the network to carry out a collaborative
            AI task. In case only two of them want to apply this methodology,
            this framework could not be applied unless one of them split its
            data into two different servers. In addition, Swarm Learning will
            require the presence of a technical expert from each of the LEA to
            establish a synchronised connection between their infrastructures.
            Regarding the potential risks, there is a possibility that the
            merged model of the framework does not improve the accuracy of the
            individual models. In that case, it will probably be necessary to
            feed the system with more data, or to try additional model
            aggregation techniques.
          </li>
          <li>
            The limitations of the Sentiment analysis module are that the
            sentiment of the social media exchange is to be measured by
            distinguishing between positive, negative and neutral sentiments.
            Further sub- categories will not be part of the module. Some general
            risks remain that basically apply to the other tools as well such as
            accuracy levels, potential remaining biases, and scenarios in which
            the different tools might not perform optimally.
          </li>
        </ul>

        <p>Measures to protect against such risks:</p>
        <ul>
          <li>Voluntary participation</li>
          <li>
            Acknowledgement of the legal and ethical framework by partners
          </li>
          <li>Accountability and documentation</li>
          <li>
            Security of retention (appropriate security measures implemented in
            storing the data)
          </li>
          <li>Proper explanations of the policy context</li>
          <li>Proper explanation as to how to use the technological tools</li>
          <li>
            The presence (online or offline) of technical experts that can be
            consulted
          </li>
          <li>No (further) processing of sensitive data</li>
          <li>Use of anonymous/anonymised datasets</li>
          <li>
            No processing of data derived from highly controversial contexts
            that might contribute to discrimination and stigmatisation
          </li>
          <li>Use of diverse and representative input datasets</li>
          <li>
            Removal of possible data that might contribute to bias,
            discrimination and stigmatisation
          </li>
          <li>Forming of diverse developer teams</li>
          <li>Constant verification and validation of the AI tools</li>
          <li>
            Collecting end-user feedback to detect bias, discrimination and
            stigmatisation.
          </li>
        </ul>
      </div>
      <div className="section">
        <h4>What are the benefits of the participation?</h4>
        <p>
          None of the users will be paid for using the platform. However, you
          may be able to learn about a steadily growing threat to domestic
          security, namely fake news and disinformation, timely efforts to
          examine and to counter this threat and – possibly – to connect with
          experts and observers with an interest in fake news and
          disinformation. With respect to the AI components, the specific
          benefits are listed as follows.
        </p>
        <ul>
          <li>
            The Disinformation sources, spread and impact analyser has the
            ability to identify whether a social media account is operated by a
            real person or a bot and to assess the spread of social media
            messages. Accordingly, one can benefit from the tool in the sense of
            figuring out whether the origins of a disinformation campaign need
            to be traced back to a real person (in the event an account turns
            out to be bot-operated) or not. LEAs can quickly launch
            investigations against real persons running accounts that have been
            used to spread illegal material. By analysing the spread of such
            messages they can identify further perpetrators and collect
            evidence.
          </li>
          <li>
            The Dynamic flows modeler – Crimes Impact Predictor has the ability
            to predict the type of crimes in a given time and location as
            facilitated by a disinformation campaign. Obviously, such
            predictions give LEAs an unusually specific overview of the criminal
            offences they are likely to witness. Accordingly, LEAs can benefit
            from the tool by taking proper counter-measures in due course, for
            example by allocating resources to a county or a region where
            increases in crime rates can be expected to occur. The prediction of
            the type of crime further facilitates this effort in the sense of
            indicating what specific challenges LEAs are likely to confront,
            which gives them the opportunity to bring proper equipment with
            them.
          </li>
          <li>
            The Swarm learning framework has the ability to fine-tune
            predictions about the crime landscape and to enable the exchange of
            non-public data from different LEAs. It will use advanced federated
            learning for joining the power of all the distributed data. The
            benefits of this capability are obvious: LEAs could jointly reach
            relevant insights without sharing their datasets, in a secure
            framework and in a very easy and quick way.
          </li>
          <li>
            And the Sentiment analysis module has the ability to assess whether
            social media messages that are of concern are positive, negative or
            neutral. This capability has the benefit of giving end-users an
            overview of the atmosphere the social media exchanges are driven by.
            From an LEA standpoint, this is particularly helpful, as it can
            contribute to a thorough threat assessment. The more negative the
            sentiment is, the more likely it is that a threat emerges.
          </li>
        </ul>
      </div>
      <div className="section">
        <h4>Confidentiality of the research/pilot study</h4>
        <p>
          All data that will be collected and processed will be treated highly
          confidentially. As explained above, no sharing will take place.
          Moreover, the data will be stored safely on servers to which only
          platform administrators have access. Appropriate security measures
          will be implemented to further ensure the confidentiality of data.
        </p>
      </div>
      <div className="section">
        <h4>Contact details</h4>
        <p>
          FERMI Project Coordinator: Dr. Holger Nitsch
          (holger.nitsch@pol.hfoed.bayern.de)
        </p>
        <p>
          Data Protection Officer: Dr Spyridon Evangelatos
          (spyros.evangelatos@netcompany.com)
        </p>
        <p>
          Technical Support Team:{" "}
          <Link
            to="#"
            className="footer-link"
            onClick={(e) => {
              window.location.href = "mailto:fermitechsupport@lists.itml.gr";
              e.preventDefault();
            }}
          >
            <span className="blue">fermitechsupport@lists.itml.gr</span>
          </Link>{" "}
        </p>
      </div>
      <div className="section"></div>
      <div className="section"></div>
    </div>
  );
}

export default Privacy;
